import * as React from "react"
import { type PageProps } from "gatsby"

import * as styles from "./page-layout.module.scss"
import { Col, Row, Space, ConfigProvider } from "antd"
import { Footer } from "antd/es/layout/layout"
import { FacebookFilled, InstagramFilled, TwitterSquareFilled } from "@ant-design/icons"

const PageLayout: React.FC<PageProps> = ({ children }) => {
  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            // Seed Token
            colorPrimary: '#C6363C',
            borderRadius: 2,
            fontSize: 18,
            colorTextHeading: 'white',
            fontFamily: "Roboto",
            colorLink: "#C6363C"
          },
          components: {
            Descriptions: {
              colorText: "white",
              colorTextBase: "white",
              titleColor: "white",
              colorFill: "white" 
            }
          }
        }}>
        <Row justify={"center"}>
        </Row>
        {children}
        <Footer>
          <Row justify="center">
            <Col xs={24} sm={12}>
              &copy; Copyright 2024 Ana the Resque <br />
              An animal rehoming charity in Serbia
            </Col>
            <Col xs={24} sm={12} className={styles.footerIcons}>
              <Space>
                <a href="https://www.facebook.com/anatherescue/" target="_blank">
                  <InstagramFilled style={{ fontSize: "2.5rem" }} />
                </a>
                <a href="https://www.instagram.com/ana_the_resque_/" target="_blank">
                  <FacebookFilled style={{ fontSize: "2.5rem" }} />
                </a>
              </Space>
            </Col>
          </Row>
        </Footer>
      </ConfigProvider >
    </>
  )
}

export default PageLayout
