exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-adopt-form-tsx": () => import("./../../../src/pages/adopt/form.tsx" /* webpackChunkName: "component---src-pages-adopt-form-tsx" */),
  "component---src-pages-adopt-index-tsx": () => import("./../../../src/pages/adopt/index.tsx" /* webpackChunkName: "component---src-pages-adopt-index-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-donate-tsx": () => import("./../../../src/pages/donate.tsx" /* webpackChunkName: "component---src-pages-donate-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-animal-template-page-tsx": () => import("./../../../src/templates/animal-template-page.tsx" /* webpackChunkName: "component---src-templates-animal-template-page-tsx" */)
}

