import * as React from "react"

import 'antd/dist/reset.css';
import './src/theme/global.module.scss';

import PageLayout from "./src/components/page-layout/page-layout"
import "@fontsource/roboto";
import "@fontsource/montserrat";

export const wrapPageElement = ({ element, props }) => {
  return (<PageLayout {...props}>{element}</PageLayout>)
}